import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { DataService } from '../../services/data-services/data-service';
import { TranslationService } from 'src/app/services/translation.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
import { MyProfileService } from 'src/app/services/my-profile-service.service';

@Component({
  selector: 'app-publishing-race-exp',
  templateUrl: './publishing-race-exp.component.html',
  styleUrls: ['./publishing-race-exp.component.scss']
})
export class PublishingRaceExpComponent {
  @Input() id!: string;
  @Input() vinOrFin!: string;
  @Input() subType!: string;
  translationData: any;
  isHidden = false;

  constructor(
    private dataService: DataService,
    private translationService: TranslationService,
    public toastService: ToastService,
    private _myProfileService: MyProfileService
  ) {

    this.dataService.hide$.subscribe(hide => this.isHidden = hide);
  }

  ngOnInit() {
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
  }

  publishExp(): void {
    const ciamId = this._myProfileService.ciamId;
    this.dataService.publishExperience(this.id, this.vinOrFin, this.subType, ciamId).subscribe(
      (data) => {
        this.toastService.show(ToastType.Success, 'RSP_SUCCESS_PUBLISH_EXP', '8%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
        this.dataService.hideElements();
      },
      (error: HttpErrorResponse) => {
        this.toastService.show(ToastType.Error, 'RSP_ERR_PUBLISH_EXP', '8%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
      })

  }
}
