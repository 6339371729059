import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Auth } from 'aws-amplify';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-not-valid-user',
  templateUrl: './not-valid-user.component.html',
  styleUrls: ['./not-valid-user.component.css']
})
export class NotValidUserComponent implements OnInit {
  translationData: any;
  logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
  auth = Auth;
  disableBtn: boolean = false;

  constructor(private _translationService: TranslationService) { }

  ngOnInit(): void {
    this._translationService.sharetranslationData.subscribe((data) => {
      this.translationData = data;
    });
  }
  backToLeaderBoard(){
    location.href=environment.domainUrl +'ranking-list/list/' + localStorage.getItem('eventId');
  }

  async triggerLogin() {
    if (this.disableBtn) {
      return
    }
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('access');
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentCIAMUser');
    sessionStorage.setItem('ciamClicked','true');
    await this.auth.signOut()
      .then((data) => {
        setTimeout(()=> {

          window.open(this.logoutUrl, "_self");

        }, 1000);

      }) 
  }
}
