<div class="map-container" #mapContainer>
    <div class="text-overlay">

        <div class="info-panel-bottom">
            <!-- Event Name -->
            <span class="event-title">{{trackDesc.trackName}}</span>
            <div class="rowele">
                <ng-container *ngIf="raceItemType === 'TRACKRACE'">
                    <app-tags-component [tagClass]="'tag-red tag'"  [tagTitle]="'Track Pace'"></app-tags-component>
                    <!-- Race distance -->
                    <app-tags-component *ngIf="!!trackDesc.distance" [tagClass]="'tag'" [tagTitle]="trackDesc.distance +' ' +'km'"></app-tags-component>

                    <!-- sectors -->
                    <app-tags-component *ngIf="!!noOfSectors" [tagClass]="'tag'" [tagTitle]="noOfSectors === 1 ? noOfSectors + ' sector' : noOfSectors + ' sectors'"></app-tags-component>
                 </ng-container>

                <ng-container *ngIf="raceItemType === 'OFFROAD'">
                    <app-tags-component [tagClass]="'tag-amber tag'"  [tagTitle]="'Offroad'"></app-tags-component>
                    <!-- Race distance -->
                    <app-tags-component *ngIf="!!trackDesc.distance" [tagClass]="'tag'" [tagTitle]="trackDesc.distance +' ' +'km'"></app-tags-component>

                </ng-container>


                
                
            </div>
        </div>
    </div>
    <div id="map">
</div>
