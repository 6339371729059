<div class="rc-shr-pnl" *ngIf="videoAssetsInfo.length != 0">
  <div class="rc-shr-title">
    <h1>{{'RSP_VIDEOS_FROM_YOUR_RACE' | customTranslation: translationData}}</h1>
    <a role="button" (click)="downloadAllVideos()" *ngIf="isDownloadButtonVisible">{{'RSP_DOWNLOAD_ALL' | customTranslation: translationData}}</a>
  </div>
  <div class="race-video-box">
    <div class="portrait-single">
      <div class="race-vid-1 rc-vid" *ngIf="emotionalAnimationIndex > -1">
        <video id="rc-vid-1" preload="auto" autoplay="autoplay" [muted]="isMuted" loop="loop" playsinline
          class="emotional-video" [poster]="videoAssetsInfo[emotionalAnimationIndex].thumbnail_url">
          <source type="video/mp4" [src]="videoAssetsInfo[emotionalAnimationIndex].url" />
        </video>
      </div>
    </div>
    <div class="landscape-double">
      <div class="race-vid-2 rc-vid" *ngIf="emotionalVideoIndex > -1">
        <video id="rc-vid-2" preload="auto" autoplay="autoplay" loop="loop" [muted]="isMuted" playsinline
          class="emotional-video" [poster]="videoAssetsInfo[emotionalVideoIndex].thumbnail_url">
          <source type="video/mp4" [src]="videoAssetsInfo[emotionalVideoIndex].url" />
        </video>
        <div class="vid-title">
          <p>{{'RSP_HOTLAP' | customTranslation: translationData}}</p>
        </div>
      </div>
      <div class="race-vid-3 rc-vid" *ngIf="faceCamAssetIndex > -1">
        <video id="rc-vid-3" preload="auto" autoplay="autoplay" loop="loop" [muted]="isMuted" playsinline
          class="emotional-video" [poster]="videoAssetsInfo[faceCamAssetIndex].thumbnail_url">
          <source type="video/mp4" [src]="videoAssetsInfo[faceCamAssetIndex].url" />
        </video>
        <div class="vid-title">
          <p>{{'RSP_ORIGINAL_RECORDING' | customTranslation: translationData}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
