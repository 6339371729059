import { ImageAssetsInfo } from './../../../../components/interfaces/race-share.interface';
import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-event-impressions',
  templateUrl: './event-impressions.component.html',
  styleUrls: ['./event-impressions.component.scss'],
})
export class EventImpressionsComponent {
  translationData: any;
  isDownloadButtonVisible: boolean = false;
  featureFlags: any;
  
  constructor(private translationService: TranslationService){
    this.featureFlags = this.translationService.featureFlags;
    this.isDownloadButtonVisible = this.featureFlags.general.enableDownloadAllButton;
  }

  @Input() images!: ImageAssetsInfo[];
  ngOnInit(){
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }


  downloadMedia() {
    this.images.forEach(image => {
      const url = image.url;
      const anchor = document.createElement('a');
      anchor.download = this.getFileNameFromUrl(url!);
      anchor.href = url!;
      anchor.click();
    });
  }

  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || 'download';
  }

}
