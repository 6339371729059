import { Pipe, PipeTransform } from "@angular/core";
import { defaultTranslationArray } from "./default-translation";


@Pipe({
  name: "customTranslation",
})
export class CustomTranslationPipe implements PipeTransform {
  defaultEnTranslation = defaultTranslationArray;
  param: any = {}


  transform(value: any, ...args: any[]): unknown {
    let translatedArray = args;
    let translatedValue: string = "";
    if (translatedArray) {
      if (translatedArray.length > 1) {
        this.param = translatedArray[0];
        translatedArray = translatedArray[1];
        translatedArray.map((item: any) => {
            if (item.key.name === value) {
              translatedValue = item.content;
              if (this.param && this.param['param']) {
                translatedValue = translatedValue.replace('{param}', this.param['param']);
              } else if (args[0].placeholders) {
                translatedValue = this.replacePlaceHolders(translatedValue, args[0].placeholders[0])
              }
              return item;
            }
      });
      } else {
      translatedArray.map((item: any) => {
        item.filter((data: any) => {
          if (data.key.name === value) {
            translatedValue = data.content;
            return data;
          }
        });
      });
      }
    }

    if (translatedValue.trim() === '') {
      this.defaultEnTranslation.forEach((data: any) => {
          if (data.key === value) {
            if (this.param && this.param['param']) {
              data.value = data.value.replace('{param}', this.param['param']);
            } else if (args[0].placeholders) {
              data.value = this.replacePlaceHolders(data.value, args[0].placeholders[0])
            }
            translatedValue = data.value;
          }
        });
      }
    return translatedValue;
  }

  private replacePlaceHolders(valueToTranslate: string, placeholders: any[]) {
    const regex = /\{placeholder\w+\}/g;
    for (let placeholder in placeholders) {
      valueToTranslate = valueToTranslate.replace(placeholder, placeholders[placeholder]);

    }
    return valueToTranslate;
  }
}
