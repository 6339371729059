import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, delay, map, of} from 'rxjs';
import { MapViewResponse, SearchResponse } from '../components/interfaces/map-view.interface';
@Injectable({
  providedIn: 'root'
})
export class AppServicesService {
public api_header:any;
baseUrl: string;
proxyBaseUrl: string  = environment.proxyEndpoint;
language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'

private showLanguageSelectionPageSubject = new BehaviorSubject<boolean>(false);
showLanguageSelectionPage$ = this.showLanguageSelectionPageSubject.asObservable();

showLanguageSelectionPage(showLangPage: boolean){
  this.showLanguageSelectionPageSubject.next(showLangPage);
}

public authorizationData =
  "Basic " + btoa("admin"+":"+"6Pu_C]ALY%dmLaX")

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiEndPoint;
   }
  dummyToken(){
    this.api_header = {
      headers: { Authorization: this.authorizationData, Locale:this.language, caller: 'Customer_Portal', 'user-role':'Customer_Portal' },
    };
    const url=encodeURI(`${this.baseUrl}/dummytoken`)
    return this.httpClient.get<any>(url,this.api_header);
  }

  getAllToursFromExpress(){
    let ciamToken = localStorage.getItem('token')? localStorage.getItem('token') : null ;
    if(ciamToken){
      let header = {
        headers: { Authorization: ciamToken},
      };
    return this.httpClient.get<any>(this.proxyBaseUrl+'/v4/search/mapview?limitForEmotionTours=250&limitForOffRoadTours=100&limitForCircuits=250&point=48,9&radius=30000', header)
    } else{
    return this.httpClient.get<any>(this.proxyBaseUrl+'/v4/search/mapview?limitForEmotionTours=250&limitForOffRoadTours=100&limitForCircuits=250&point=48,9&radius=30000')
    } 
  }

  getCircuitDetailFromExpress(guid: any) {
    const url = this.proxyBaseUrl+`/v1/circuit?guid=${guid}`;
    let ciamToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    if (ciamToken) {
      let header = {
        headers: { Authorization: ciamToken },
      };
      return this.httpClient.get<any>(url, header);
    } else {
      return this.httpClient.get<any>(url);
    }

  }

  getClusterAnnotations(guid:any){
    const url=encodeURI(`${this.proxyBaseUrl}/v1/tour/cluster?guid=${guid}`)
    return this.httpClient.get<any>(url);
  }

  getTrackAnnotations(guid:any){
    const url=encodeURI(`${this.proxyBaseUrl}/v1/annotation/geojson?guid=${guid}`)
    return this.httpClient.get<any>(url);
  }

  getAnnotationDetails(guid:any){
    const url=encodeURI(`${this.proxyBaseUrl}/v1/annotation/details?annotationId=${guid}`)
    return this.httpClient.get<any>(url);
  }

  getAllEmotionTour(): Observable<MapViewResponse> {
    const url = encodeURI(`${this.proxyBaseUrl}/v4/search/mapview?limitForEmotionTours=250&limitForOffRoadTours=100&limitForCircuits=250&point=48,9&radius=30000`)
    return this.httpClient.get(url);
  }

  getMapCircuitList(
    zoomLevel: any,
    point:any,
    radius:any
  ): Observable<any> {
    const url = `${this.proxyBaseUrl}/v3/search/mapview?radius=${radius}&point=${point.lat},${point.lng}&limitForCircuits=250&limitForOffRoadTours=100&limitForEmotionTours=100&level=${zoomLevel}`;
    return this.httpClient.get<any>(url);
  }

  public gobalSearch(
    pageSize: any,
    pageNo: any,
    nameMatches: any,
    isCircuits: boolean,
    isEmotionTour: boolean,
    isOffroadzSelected: boolean
  ): Observable<any> {
    const url = encodeURI(
      `${this.proxyBaseUrl}/v1/search?pageSize=${pageSize}&pageNumber=${pageNo}&nameMatches=${nameMatches}&circuits=${isCircuits}&emotionTours=${isEmotionTour}&offRoadTours=${isOffroadzSelected}`
    );
    return this.httpClient.get(url);
  }

 

  downloadTrackDetails(guid: any) {
    let api_headers:any = {
      headers: {
        'responseType':"arraybuffer"
      }
    };
    const url = `${this.proxyBaseUrl}/v1/track/download?guid=${guid}`;
    return this.httpClient.get(url, api_headers);
  }


  getCircuit(guid: any, token:any) {
    let ciamToken = localStorage.getItem('token')? localStorage.getItem('token') : token ;
    this.api_header = {
      headers: { Authorization: "Bearer " + ciamToken,Locale:this.language,caller:'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = `${this.baseUrl}/v1/circuit?guid=${guid}`;
    return this.httpClient.get<any>(url, this.api_header);
  }

  

  public anonymize(participantId: any, participantKey:any, token: string): Observable<any> {
    const api_header =  { Authorization: "Bearer " + token, 'Content-Type': 'application/json'};
    const baseUrl= environment.serverUrl; 
    const url = `${baseUrl}api/gdprV2/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this.httpClient.put(url,{}, {headers: api_header});
  }

  /**
   * @description called when we click on the profile icon
   * @returns User profile details
   */

  /**
   * @description called when we save the profile information from the popup
   * @param nickName 
   * @param description 
   * @param socialLink 
   * @param ciamId 
   * @returns updated profile information
   */
  public updateProfile(nickName: string, description: string, socialLink: string, ciamId: any): Observable<any> {
    const api_header = { 'Content-Type': 'application/json', 'ciamId': ciamId};
    const baseUrl= environment.proxyEndpoint +'/stories/updateUser?isStories=0';
    return this.httpClient.put(baseUrl, {nickName, description, socialLink},{headers: api_header});
  }

  setFavouriteTrackStatus(token:any,trackId:any,favStatus:boolean){
    const api_header =  { Authorization: "Bearer " + token, 'Content-Type': 'application/json',caller:'Customer_Portal','user-role':'Customer_Portal'};
    const baseUrl= environment.apiEndPoint; 
    const url = `${baseUrl}/v1/favorite/track`;
    return this.httpClient.post(url,{"id": trackId,
    "favorite" :favStatus}, {headers: api_header});
  }

  getUserFavTracks(token:any) {
    this.api_header = {
      headers: { Authorization: "Bearer " + token,Locale:this.language,caller:'Customer_Portal','user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = `${this.baseUrl}/v1/favorite/track`;
    return this.httpClient.get<any>(url, this.api_header);
  }
  
  
} 
