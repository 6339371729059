import { Injectable } from '@angular/core';
import {ProfileDetails} from '../components/interfaces/myStories.interface'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MyProfileService {

    private profileInfo: ProfileDetails = <any>null;
  private _ciamId!: string;
    constructor(private _httpClient: HttpClient){}
    public set profileInfoResponse(data: ProfileDetails){
        this.profileInfo = data;
    }

    public get profileInfoResponse() : ProfileDetails{
        return this.profileInfo;
    }

   
  /**
   * @description called when login for Profile Information
   * @returns User profile details
   */
  public getProfile(): Observable<any> {
    const url = `${environment.proxyEndpoint}/stories/getUser?isStories=0`;
    return this._httpClient.get<any>(url);
  }



  get ciamId() : string{
    return this._ciamId;
  }

  set ciamId(userId: string) {
    this._ciamId = userId;
  }


}
