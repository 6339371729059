import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-response-page',
  templateUrl: './response-page.component.html',
  styleUrls: ['./response-page.component.scss']
})
export class ResponsePageComponent {
  @Input() success:any;
  @Input() failure:any;
  @Input() info:any;
  @Input() infoMessageAlert:any;
  @Input() responseMessage: any;
  @Input() responseInfoMessage:any
  translationData = [];
  constructor(private translationService: TranslationService){
  }

  ngOnInit(): void {

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }
}
