import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/translation.service';


@Injectable({
  providedIn: 'root'
})
export class ProdEnabledGuard implements CanActivate {

  enabledPath = [
    'coming-soon',
    'login',
    '/stories/anonymize',
    '/stories/link-participant',
    '/stories/processing',
    '/stories/verify',
    'my-race-details',
    '/stories/experience',
    '/stories/register',
  ];
  nonProductionPath = '/stories';
  featureFlags!: any;
  isMyStoriesVisible: any;


  constructor(private router: Router, private translationService: TranslationService){
    this.featureFlags = this.translationService.featureFlags;
    this.isMyStoriesVisible = this.featureFlags.circuitDetails.enableStories;

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!environment.production) {
      return true;
    } else if(this.enabledPath.some(path => state.url.includes(path))) {
      return true;
    }
    else {
      if (state.url.includes(this.nonProductionPath) && this.isMyStoriesVisible) {
        return true;

      }
      this.router.navigateByUrl('coming-soon');
      return false;
    }
  }

}
