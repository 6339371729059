import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import { AppServicesService } from 'src/app/services/app-services.service';
import { EventInfoResponse } from 'src/app/interfaces/event-info.interface';
import { TourDbService } from '../../../../components/landing-page/landingpage.db';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MyStoriesService } from 'src/app/modules/stories/services/my-stories.service';

@Component({
  selector: 'app-ciam-confirm-link',
  templateUrl: './ciam-confirm-link.component.html',
  styleUrls: ['./ciam-confirm-link.component.scss']
})
export class CiamConfirmLinkComponent implements OnInit {
  eventInfoResponse : EventInfoResponse = <any>null;
  logstatus: any;
  auth = Auth;
  logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
  isCiamLinked: boolean = false;
  isConfirmClicked:  boolean = false;
  participants: any;
  profileImage : any;
  isProfileImageUrl: boolean = false;
  eventStartDate: any;
  eventEndDate: any;
  isSpinnerLoads:boolean = true;
  translationData = [];
  selectedLang:any;
  language: string = '';
  isDifferentCiamLinked: boolean = false;
  isAnonymisedParticipant: boolean = false;
  constructor(private _authService: AwsAuthService, private _location: Location, private _db: TourDbService,
    private _appService: AppServicesService, private router: Router, private translationService: TranslationService,
    private languageService: LanguageService, private _myStoriesService : MyStoriesService)
   { 
    // this._db.openDb();
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
   }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.getParticipantInfo();
    

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

 public getParticipantInfo(): void{

    this.participants= sessionStorage.getItem('partcipationDetails');
    let userLoggedIn = this._authService.getUserLoggedInStatus();
    const langInUrl = this.router.url.split('/')[1];
    if(!this.participants || !userLoggedIn || 
      !this.languageService.checkIsValideLanguage(langInUrl)){
      this.router.navigateByUrl('/'+this.language+'/404');
      return;
    }
    if (this.participants) {
      this.participants = JSON.parse(this.participants);
    }   
    this._myStoriesService.getParticipantInfo(this.participants.participantId, this.participants.participantKey).subscribe((data: EventInfoResponse)=>{
      if(data){
        this.isCiamLinked = data.participantInfo.isCiamLinked;
        
        this.eventInfoResponse = data;
        // this.isProfileImageUrl= true;
        this.profileImage = data.ciamInfo.profileImageUrl;
        this.isSpinnerLoads = false;
        if(data.eventInfo?.startDate && data.eventInfo?.endDate) {
          this.eventStartDate = new Date(data.eventInfo?.startDate);
          this.eventEndDate = new Date(data.eventInfo?.endDate);
        }
        
        
        this.languageService.participantLocale = data.participantInfo.participantLocale ?? 'de-DE';
          if (this.isCiamLinked) {
            this.router.navigateByUrl('/' + this.language + '/stories/' + this.participants.participantId + '/my-race-details');
          }
      }
    }, (error: HttpErrorResponse )=> {
      this.isSpinnerLoads = false;
      if(error.error['error-code'] === "1930"){
        this.isDifferentCiamLinked = true;
      }
      else if(error.error['error-code'] === "1408"){
        this.isAnonymisedParticipant = true;
      }
      else{
        this.router.navigateByUrl('/'+this.language+'/404');
        console.log('Error while fetching participant info');
      }
      
    })
  }
  // triggerLogin() {
  //   this.awsAuth.signIn();
  //   this.signedIn = true;
  // }
public confirm() : void{
  this.isConfirmClicked=true;
  this._myStoriesService.updateCiamConfirmation(this.participants?.participantId, this.participants?.participantKey
    ).subscribe(
    (response) => { 
      this._myStoriesService.particpantInfoResponse = response;
      this.router.navigateByUrl('/'+this.language+'/stories/'+this.participants.participantId+'/my-race-details');
    }, (error: HttpErrorResponse) => {
      const errorStatusCodes = [400]
      // CIAM is already linked then show my details page
      if (errorStatusCodes.includes(error.status)) {
        // We are not supposed to show any race details right???
        this.router.navigateByUrl('/'+this.language+'/stories/'+this.participants.participantId+'/my-race-details');
      } else {
        this.router.navigateByUrl('/'+this.language+'/404');
      }
    }
  );
}

 async  logout() {
   let paricipantInfo = sessionStorage.getItem("participantInfo");
  let pariticipantVerificationUrl =   "/stories/verify/"+paricipantInfo;
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('routeUrl');
    // this.logstatus.unsubscribe();
    // emit subject with true
  
    sessionStorage.setItem('ciamClicked','true');
    await this.auth.signOut()

      .then((data) => {
        setTimeout(()=> {

          window.open(pariticipantVerificationUrl, "_self");

        }, 1000);

      })  

  }


}
