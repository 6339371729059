import { Component, Input } from '@angular/core';
import { RaceInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-telemetric-data-card',
  templateUrl: './telemetric-data-card.component.html',
  styleUrls: ['./telemetric-data-card.component.scss']
})
export class TelemetricDataCardComponent {
@Input() raceInfo!: RaceInfo;
translationData: any;
driftScore!: number;
yawRate!: number;
inclineAngle!: number;
airTemp!: number;
@Input() isDriftChallenge: boolean = false;
@Input() isOffroad: boolean = false;
@Input() isbestTime: boolean = false;
participantLocale: string = localStorage.getItem('language')|| 'en-GB';
avgDriftAngle!: number;
maxDriftSpeed!: number;
driftDistance!: number;
private OdometerObserver!: IntersectionObserver;
odometerConfig = { auto: false, format: this.getOdometerNumberFormat() , duration: 3000};
odometerConfigmaxSpeed = { auto: false, format: !this.isDriftChallenge ? 'd': this.getOdometerNumberFormat(), duration: 3000};
odometerConfigDriftmaxSpeed = { auto: false, format: 'd', duration: 3000};

odometerConfigTireTempAndmaxBrakeTorque = {auto: false, format: this.getOdometerNumberFormat(), duration: 3000};
odometerConfigavgDriftAngle = {auto: false, format: this.getOdometerNumberFormat() , duration: 3000}

constructor(private translationService: TranslationService){}

ngAfterViewInit(): void {
  this.setUpOdometer();
}

public ngOnChanges(): void {
    this.odometerConfigmaxSpeed = { auto: false, format: !this.isDriftChallenge ? 'd': this.getOdometerNumberFormat(), duration: 3000};
}

ngOnInit(){
  this.translationService.getTranslationDataAsObservable().subscribe(
    (translationData: any) => {
      this.translationData = translationData;
    }
  )
  this.setUpOdometerValues();
}

/**
   * @description initialize the odometer
   */
setUpOdometer(){
  const target = document.getElementById('stats-info-bar');

  if (!target) {
    return;
  }

  this.OdometerObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.5) {
        this.odometerConfig = { ...this.odometerConfig, auto: true };
        this.odometerConfigmaxSpeed = { ...this.odometerConfigmaxSpeed, auto: true };
        this.odometerConfigavgDriftAngle = { ...this.odometerConfigavgDriftAngle, auto: true };
        this.odometerConfigTireTempAndmaxBrakeTorque = { ...this.odometerConfigTireTempAndmaxBrakeTorque, auto: true };
        this.odometerConfigDriftmaxSpeed = { ...this.odometerConfigDriftmaxSpeed ,auto: true};
      } else {
        this.odometerConfig = { ...this.odometerConfig, auto: false };
        this.odometerConfigmaxSpeed = { ...this.odometerConfigmaxSpeed, auto: false };
        this.odometerConfigavgDriftAngle = { ...this.odometerConfigavgDriftAngle, auto: false };
        this.odometerConfigTireTempAndmaxBrakeTorque = { ...this.odometerConfigTireTempAndmaxBrakeTorque, auto: false };
        this.odometerConfigDriftmaxSpeed = { ...this.odometerConfigDriftmaxSpeed ,auto: false};
      }
    });
  }, {
    threshold: [0, 0.5, 1]
  });

  this.OdometerObserver.observe(target);
}


/**
   * @description initialize the values in odometer
   */
setUpOdometerValues(){
  this.yawRate = this.raceInfo.maxValues.maxYawRate ?? 0
  this.inclineAngle = this.raceInfo.maxValues.maxInclineAngle ?? 0
  this.airTemp = this.raceInfo.maxValues.maxAirTemp ?? 0
  this.driftScore = this.raceInfo.driftScore ?? 0;
  // changed from avgDriftAngle to maxDriftAngle
  this.avgDriftAngle = this.raceInfo.maxValues.maxDriftAngle ?? 0;
  this.maxDriftSpeed = this.raceInfo.maxValues.maxDriftSpeed ?? 0;
  this.driftDistance = this.raceInfo.maxValues.totalDriftDistance ?? 0;
}


  /**
   * @description code will return a thousand format seperator
   * Needs to be handled better than taking from local storage
   * @returns string
   */
  public getOdometerNumberFormat(): string {
    const locale = this.participantLocale.split('-')[0]
    const formatLangList = ['de', 'es', 'fr', 'cs']
    if (formatLangList.includes(locale)) {
      return '(.ddd),ddd'
    } else {
      return '(,ddd).ddd'
    }
  }

ngOnDestroy(): void {
  if (this.OdometerObserver) {
    this.OdometerObserver.disconnect();
  }
}
}