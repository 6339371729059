import { Component, OnInit,} from '@angular/core';
import { Auth } from 'aws-amplify';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import { AppServicesService } from 'src/app/services/app-services.service';
import { EventInfoResponse, UserInfo } from 'src/app/interfaces/event-info.interface';
import { TourDbService } from '../../../../components/landing-page/landingpage.db';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MyStoriesService } from 'src/app/modules/stories/services/my-stories.service';
@Component({
  selector: 'app-ciam-confirm-link',
  templateUrl: './ciam-confirm-link.component.html',
  styleUrls: ['./ciam-confirm-link.component.scss']
})
export class CiamConfirmLinkComponent implements OnInit {
  eventInfoResponse : EventInfoResponse = <any>null;
  userInfo : UserInfo = <any>null
  logstatus: any;
  auth = Auth;
  logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
  isCiamLinked: boolean = false;
  isConfirmClicked:  boolean = false;
  participants: any;
  profileImage : any;
  isProfileImageUrl: boolean = false;
  eventStartDate: any;
  eventEndDate: any;
  isSpinnerLoads:boolean = true;
  translationData = [];
  selectedLang:any;
  language: string = '';
  isDifferentCiamLinked: boolean = false;
  isAnonymisedParticipant: boolean = false;
  selfRegisteration: boolean = false;
  isChecked:boolean = false;
  eventId: any = '';
  translatedArray = [];
  finalLang = false;
  registerUserClicked: boolean = false;
  successResponse:boolean = false;
  responseMessage: string = ''
  responseInfoMessage: string = "CP_SM_REGISTER_RESPONSE_SUB_MSG";
  constructor(private _authService: AwsAuthService, private _location: Location, private _db: TourDbService,
    private _appService: AppServicesService, private router: Router, private translationService: TranslationService,
    private languageService: LanguageService, private _myStoriesService : MyStoriesService)
   { 
    // this._db.openDb();
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
   }

  ngOnInit(): void {
    let url = this.router.url;
      this.eventId = sessionStorage.getItem('eventId')
    if(url.includes('register-user')){
      this.selfRegisteration = true;
      this.getUserInfo()
    }else{
    this.language = this.languageService.getLanguage();
    this.getParticipantInfo();
  }
  this.translationService.getTranslationDataAsObservable().subscribe(
    (translationData: any) => {
      this.translationData = translationData;
    }
  )  
  }

 public getParticipantInfo(): void{

    this.participants= sessionStorage.getItem('partcipationDetails');
    let userLoggedIn = this._authService.getUserLoggedInStatus();
    const langInUrl = this.router.url.split('/')[1];
    if(!this.participants || !userLoggedIn || 
      !this.languageService.checkIsValideLanguage(langInUrl)){
      this.router.navigateByUrl('/'+this.language+'/404');
      return;
    }
    if (this.participants) {
      this.participants = JSON.parse(this.participants);
    }   
    this._myStoriesService.getParticipantInfo(this.participants.participantId, this.participants.participantKey).subscribe((data: EventInfoResponse)=>{
      if(data){
        this.isCiamLinked = data.participantInfo.isCiamLinked;
        this.eventInfoResponse = data;
        // this.isProfileImageUrl= true;
        this.profileImage = data.ciamInfo.profileImageUrl;
        this.isSpinnerLoads = false;
        if(data.eventInfo?.startDate && data.eventInfo?.endDate) {
          this.eventStartDate = new Date(data.eventInfo?.startDate);
          this.eventEndDate = new Date(data.eventInfo?.endDate);
        }
        
        
        this.languageService.participantLocale = data.participantInfo.participantLocale ?? 'de-DE';
          if (this.isCiamLinked) {
            this.router.navigateByUrl('/' + this.language + '/stories/' + this.participants.participantId + '/my-race-details');
          }
      }
    }, (error: HttpErrorResponse )=> {
      this.isSpinnerLoads = false;
      if(error.error['error-code'] === "1930"){
        this.isDifferentCiamLinked = true;
      }
      else if(error.error['error-code'] === "1408"){
        this.isAnonymisedParticipant = true;
      }
      else{
        this.router.navigateByUrl('/'+this.language+'/404');
        console.log('Error while fetching participant info');
      }
      
    })
  }
  // triggerLogin() {
  //   this.awsAuth.signIn();
  //   this.signedIn = true;
  // }
public getUserInfo(){
    
  this.participants= sessionStorage.getItem('partcipationDetails');
    let userLoggedIn = this._authService.getUserLoggedInStatus();
    const langInUrl = this.router.url.split('/')[1];
    if(!userLoggedIn || 
      !this.languageService.checkIsValideLanguage(langInUrl)){
      this.router.navigateByUrl('/'+this.language+'/404');
      return;
    }
  this._myStoriesService.getUserInfo(this.eventId).subscribe({
    next: (data: UserInfo) => {
      if(data){
        const currentPath = this._location.path();
        const pathParts = currentPath.split('/');
        let languageCode = pathParts[1];
        if (languageCode !== data.ciamInfo.locale) {
          this.language = data.ciamInfo.locale;                    
          const currentURL = this.router.url.split('/');
          currentURL[1] = this.language;
          localStorage.setItem('language', this.language);
          this.languageService.setIsNotParticipantSelectedLang(false);
          this._location.go(currentURL.join('/'));
        } 
        this.languageService.setParticipantLanguage(this.language);   
        
        this.getTranslations(this.language); 
        this.userInfo = data;
      }
    },
    error: (error: HttpErrorResponse) => {
      this.isSpinnerLoads = false;
      if(error.error['error-code'] === "1412"){
        this.selfRegisteration = false
        this.registerUserClicked = true;
        this.successResponse = true
        this.isSpinnerLoads = false;
        this.responseMessage = 'CP_SM_SELF_REG_MSG'
      }
      else{
        this.router.navigateByUrl('/'+this.language+'/404');
      }
    }
  })
  }

public register(){
  this.isSpinnerLoads = true;
  this.registerUserClicked = true
  this._myStoriesService.registerUser(this.eventId).subscribe({
    next: (data) => {
      if(data){
        this.successResponse = true
        this.isSpinnerLoads = false; 
        this.responseMessage = 'CP_SM_SELF_REG_SUCCESS_MSG';
        
      }
    },
    error: (error: HttpErrorResponse) => {
      this.isSpinnerLoads = false;
      this.router.navigateByUrl('/'+this.language+'/404');
    }
  })
  this.selfRegisteration = false
  } 
  
public getTranslations(languageId: string) { 

   this.translationService
    .phraseApiCallForTranslations(languageId)
    .subscribe({
      next: (allDeTranslationBasedOnlocle: string | any[]) => { 
        for (let i = 0; i < allDeTranslationBasedOnlocle.length; i++) {
          this.translatedArray = this.translatedArray.concat(allDeTranslationBasedOnlocle[i]);
        }      
        this.translationService.setTranslationData(this.translatedArray);
        this.translationService.sendSelectedLanguage(this.language);
        this.translationService.sendtranslationValue(this.translatedArray);
        this.finalLang = true;
        this.isSpinnerLoads = false;
      },
      error: (err: any) => { 
        this.finalLang = true;
        this.isSpinnerLoads = false; 
      }
    });

  }

public confirm() : void{
  this.isConfirmClicked=true;
  this._myStoriesService.updateCiamConfirmation(this.participants?.participantId, this.participants?.participantKey
    ).subscribe(
    (response) => { 
      this._myStoriesService.particpantInfoResponse = response;
      this.router.navigateByUrl('/'+this.language+'/stories/'+this.participants.participantId+'/my-race-details');
    }, (error: HttpErrorResponse) => {
      const errorStatusCodes = [400]
      // CIAM is already linked then show my details page
      if (errorStatusCodes.includes(error.status)) {
        // We are not supposed to show any race details right???
        this.router.navigateByUrl('/'+this.language+'/stories/'+this.participants.participantId+'/my-race-details');
      } else {
        this.router.navigateByUrl('/'+this.language+'/404');
      }
    }
  );
}

 async  logout() {
   let paricipantInfo = sessionStorage.getItem("participantInfo");
  let pariticipantVerificationUrl =   "/stories/verify/"+paricipantInfo;
  if(this.selfRegisteration == true){
    let eventId = sessionStorage.getItem("eventId")
    pariticipantVerificationUrl = "/stories/register"+eventId;
  }
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentCIAMUser');
    localStorage.removeItem('token');

    // localStorage.removeItem('routeUrl');
    // this.logstatus.unsubscribe();
    // emit subject with true
  
    sessionStorage.setItem('ciamClicked','true');
    await this.auth.signOut()

      .then((data) => {
        setTimeout(()=> {
          window.open(pariticipantVerificationUrl, "_self");
        }, 1000);

      })  

  }


}
