<div id="race-info-main">
  <app-event-owner-details *ngIf="experienceOwnerName" [experienceOwner]="experienceOwnerName"
    [profileInitials]="profileInitials" [businessOwner]="eventDetails.businessOwnerInfo"
    [profileImage]="participantProfileImage" [subType]="subType" [itemType]="itemType">
  </app-event-owner-details>
  <app-event-name [subType]="subType" [eventName]="
      eventDetails.eventInfo && eventDetails.eventInfo.eventName
        ? eventDetails.eventInfo.eventName
        : ''
    " [raceName]="raceName"></app-event-name>
  <div id="race-eve-tag-list" class="tag-area">
    <!--Templates based on whether track is offroad or not. Will add other templates container based on new track types-->
    <ng-container *ngIf="isTrackRace">
      <span>
        <app-tags-component [tagClass]="'tag-red tag'" *ngIf="isBestTime" [tagTitle]="exercisename"> 
      </app-tags-component>
        <app-tags-component [tagClass]="'tag-red tag'" *ngIf="isDriftChallenge"
        [tagTitle]="exercisename"></app-tags-component>
        
      <app-tags-component *ngIf="subType === 'PERSONAL'" [tagClass]="'tag-red tag'"
          [tagTitle]="lapCount === 1 ? lapCount + ' lap' : lapCount + ' laps'"></app-tags-component>
      </span>
      <span>
        <app-tags-component [tagClass]="'tag'" *ngIf="raceTime && !isOffroad" [tagTitle]="raceTime + ' min'">
        </app-tags-component>
      </span>
    </ng-container>

    <ng-container *ngIf="isOffroad">
      <span><app-tags-component [tagClass]="'tag-amber tag'" [tagTitle]="exercisename"></app-tags-component></span>
    </ng-container>

    
    <!--Common tags here-->
    <span>
      <app-tags-component *ngIf="!!eventDetails.raceInfo.startTime" [tagClass]="'tag'"
        [tagTitle]="eventDetails.raceInfo.startTime | date : 'dd.MM.yyyy'"></app-tags-component>
    </span>
    <span><app-tags-component [tagClass]="'tag'" *ngIf="!!eventDetails.vehicleInfo.vehicleModel"
        [tagTitle]="eventDetails.vehicleInfo.vehicleModel"></app-tags-component></span>
  </div>
  <app-event-description *ngIf="subType === 'EVENT'" [eventDescription]="
      eventDetails.eventInfo && eventDetails.eventInfo.eventDescription
        ? eventDetails.eventInfo.eventDescription
        : ''
    "></app-event-description>
</div>
