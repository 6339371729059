<div id="bg" *ngIf="!isHidden">
  <div class="main-inf">
    <div class="info-container">
      <div class="info-msg">
        <h1 class="info-title">{{'RSP_INFO_TITLE' | customTranslation: translationData}}</h1>
        <div class="info-group">
          <p class="info-para">{{'RSP_INFO_MSG' | customTranslation: translationData}}</p>
        <div class="publish-btn">
          <button class="publish-button" (click)="publishExp()"><span>{{'RSP_INFO_PUBLISH_TITLE' | customTranslation: translationData}}</span></button>
        </div>
        </div>
      </div>
    </div>
    <div class="second-col">
      <div class="cars-image">
        <picture>
          <source media="(min-width:1280px)"
            srcset="../../../../../assets/images/img-publish-teaser-fallback-on-track.png">
          <source media="(min-width:768px)"
            srcset="../../../../../assets/images/img-publish-teaser-fallback-on-tracktab.png">
          <source media="(min-width:280px)"
            srcset="../../../../../assets/images/img-publish-teaser-fallback-on-trackphone.png">
          <img src="../../../../../assets/images/img-publish-teaser-fallback-on-track.png" alt="error">
        </picture>
      </div>
    </div>
  </div>
</div>
