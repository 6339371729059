<div class="rc-shr-pnl">
<div class="rc-shr-title">
  <h1>{{'RSP_IMPRESSIONS' | customTranslation: translationData}}</h1>
  <a role="button" (click)="downloadMedia()" *ngIf="isDownloadButtonVisible">{{'RSP_DOWNLOAD_ALL' | customTranslation: translationData}}</a>
</div>

<div class="containers">

  <div class="grid">

    <div class="grid-item" *ngFor="let image of images;">

      <img [src]="image.url" alt="Image">

    </div>

  </div>

</div>

</div>
