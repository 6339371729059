
<ng-container>
    <div class="confirm-button-app">
      <div class="alert-icon-reg">
          <div class="alert-icon ">
              <span  *ngIf="failure" class="icon icon_failed"></span>
              <span  *ngIf="success"class="icon icon_success"></span>
              <span  *ngIf="info"class="icon icon_info"></span>
              <span  *ngIf="infoMessageAlert"class="icon icon_success icon-info-msg"></span>
          </div>
      </div>
      <div>
            <span class="info-msg">{{responseMessage | customTranslation: translationData}}</span>
            <span class="subInfo-msg">{{responseInfoMessage | customTranslation: translationData}}</span>
    </div>
  </div> 
</ng-container>