<div class="usr-ptnr-inf" [ngClass]="(businessOwner &&  businessOwner.profileImage) ? 'usr-ptnr-inf' : 'usr-no-ptnr-inf'">
    <div id="usr-ptnr-avtr">
        <figure class="usr">
            <img *ngIf="profileImage" [src]="profileImage">
            <div *ngIf="!profileImage" class="initals">
                <span>{{profileInitials}}</span>
            </div>
        </figure>
        <figure class="bis" *ngIf="subType === 'EVENT' && !isBusinessOwnerEmpty()">
          <img *ngIf="businessOwner.profileImage" [src]="businessOwner.profileImage">
          <div *ngIf="!businessOwner.profileImage" class="initals">
              <span>{{businessOwnerInitials}}</span>
          </div>
      </figure>
    </div>
    <!-- <div id="usr-ptnr-txt">
        <span class="rc-prt">{{experienceOwner}}</span>
        <span *ngIf="subType ==='PERSONAL'">{{'RSP_VEHICLE_SHAREEXPERIENCE' | customTranslation: translationData}}</span>
        <span *ngIf="businessOwner?.Name && subType ==='EVENT'">{{'RSP_EVENT_OWNER_WITH_TITLE' | customTranslation: translationData}}</span>
        <span class="rc-prt">{{subType ==='EVENT' ? businessOwnerName : ''}}</span>
    </div> -->
    <div id="usr-ptnr-txt">
        <!-- Event race changes start here-->
        <span *ngIf="subType ==='EVENT' && !businessOwner?.Name" class="rc-prt">{{experienceOwner}}</span>
        <span *ngIf="subType ==='EVENT' && businessOwner?.Name"
        [innerHTML]="'RSP_EVENT_OWNER_WITH_BUSINESS_OWNER' | customTranslation: {
            placeholders: [
               {
                  '{placeholderOwnerName}': experienceOwner,
                  '{placeholderBusinessGroup}': businessOwnerName
               }
            ]
         } : translationData"></span>
         <!--Event race changes end here-->
         <!--Personal Race Data start here-->
           <span *ngIf="subType ==='PERSONAL'"
           [innerHTML]="'RSP_PERSONAL_RACE_SHARED_TITLE' | customTranslation: {
               placeholders: [
                  {
                     '{placeholderOwnerName}': experienceOwner,
                  }
               ]
            } : translationData"></span>
        <!--Personal Race Data end here-->
        <!--Personal Race Data end here-->
    </div>
</div>
