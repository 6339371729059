import { Component, Input } from '@angular/core';
import { VideoAssetsInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-race-videos',
  templateUrl: './race-videos.component.html',
  styleUrls: ['./race-videos.component.scss'],
})
export class RaceVideosComponent {
  @Input() videoAssetsInfo!: VideoAssetsInfo[];
  isMuted = true;
  translationData: any;
  emotionalAnimationIndex: number = -1;
  faceCamAssetIndex: number = -1;
  emotionalVideoIndex: number = -1;
  videoResponse: any;
  featureFlags: any;
  isDownloadButtonVisible: boolean = false;


  constructor(private translationService: TranslationService) {
    this.featureFlags = this.translationService.featureFlags;
    this.isDownloadButtonVisible = this.featureFlags.general.enableDownloadAllButton;
   }

  ngOnInit() {
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
    this.getEmotionalVideoOnLoad();
    this.loadFaceCamAssetOnLoad();
    this.loadEmotionalAnimationAssetOnLoad();
    this.videoResponse = this.videoAssetsInfo;
  }

  public downloadAllVideos(): void {
    const video1 = this.videoAssetsInfo[this.faceCamAssetIndex]?.url;
    const video2 = this.videoAssetsInfo[this.emotionalVideoIndex]?.url;
    const video3 = this.videoAssetsInfo[this.emotionalAnimationIndex]?.url;

    if (video1) {
      const a1 = document.createElement('a');
      a1.download = 'face_cam.mp4';
      a1.href = video1;
      a1.click();
    }

    if (video2) {
      const a2 = document.createElement('a');
      a2.href = video2;
      a2.download = 'emotional_video.mp4';
      document.body.appendChild(a2);
      a2.click();
      document.body.removeChild(a2);
    }

    if (video3) {
      const a3 = document.createElement('a');
      a3.href = video3;
      a3.download = 'emotional_animation.mp4';
      document.body.appendChild(a3);
      a3.click();
      document.body.removeChild(a3);
    }
  }

  public loadEmotionalAnimationAssetOnLoad() {
    this.emotionalAnimationIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'EMOTIONAL_ANIMATION'
    );
  }

  public getEmotionalVideoOnLoad() {
    this.emotionalVideoIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'EMOTIONAL_VIDEO'
    );
  }

  public loadFaceCamAssetOnLoad() {
    this.faceCamAssetIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'FACECAM'
    );
  }
}
